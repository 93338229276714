import { AxiosResponse } from 'axios'
import axios from '../instance'

export const generateCourierLineNotifyConectionCode = async (
	courierId: string
): Promise<any> => {
	try {
		const response = await axios.get(
			`/admin/system/courier-line-notify-chat-list/connect-code/${courierId}`
		)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getCourierLineNotifyChatList = async (): Promise<any> => {
	try {
		const response = await axios.get(
			'/admin/system/courier-line-notify-chat-list'
		)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const disconnectCourierLineNotify = async (
	courierId: string
): Promise<any> => {
	try {
		const response = await axios.post(
			`/admin/system/courier-line-notify-chat-list/disconnect/${courierId}`
		)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateCourierLineNotifySetting = async (
	courierId: string,
	key: string,
	value: boolean
): Promise<any> => {
	try {
		const response = await axios.put(
			`/admin/system/courier-line-notify-chat-list/setting/${courierId}`,
			{
				[key]: value,
			}
		)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
