export enum SETTING_CONFIG_TYPE {
	PAYMENT_GATEWAY_CHANNEL_BZT = 'PAYMENT_GATEWAY_CHANNEL_BZT',
	SEND_LINE_NOTIFY_CHANNEL = 'SEND_LINE_NOTIFY_CHANNEL',
}

export enum PAYMENT_GATEWAY_CHANNEL {
	SCB_BZT = 'SCB_BZT',
	FLASH_PAY_BZT = 'FLASH_PAY_BZT',
}

export enum SEND_LINE_NOTIFY_CHANNEL {
	FINE_FLOW = 'FINE_FLOW',
	LINE_MESSAGING_API = 'LINE_MESSAGING_API',
}

export interface List {
	type: string
	value: string
}

export interface IListPayload {
	list: List[]
}
