import axios from '../instance'
import { IListPayload } from './types'

export const getSystemPaymentList = async () => {
	const { data } = await axios.get('/admin/system/setting-config')
	return data
}

export const updateSystemSettingConfig = async (payload: IListPayload) => {
	//
	const { data } = await axios.put('/admin/system/setting-config', payload)
	return data
}
